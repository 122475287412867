<template>
  <div class="page">
    <el-form size="small" :inline="true" class="query-form" ref="searchForm">
      <el-form-item prop="type">
        <el-input size="small" v-model="srhStr" placeholder="请输入文件名称" maxlength="66" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询</el-button>
        <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
      </el-form-item>
      <el-form-item style="float:right;">
        <!-- <el-button icon="el-icon-document" size="small" plain @click="getLog">日志</el-button> -->
        <el-button type="primary" @click="emptyRecycle()" size="small">清空回收站</el-button>
      </el-form-item>
    </el-form>
    <div class="top bg-white">
      <el-row :gutter="20">
        <el-col :span="4" :offset="20">
          <div style="float:right;">
            <el-button type="primary" size="small" :disabled="dataListSelections.length <= 0"
              @click="restore()">还原</el-button>
            <el-button type="danger" size="small" :disabled="dataListSelections.length <= 0"
              @click="del()">彻底删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-menu :default-active="tabIndex" class="el-menu-demo" mode="horizontal" @select="handleSelectTab">
        <el-menu-item index="1">收集整编</el-menu-item>
        <el-menu-item index="2">文物清单</el-menu-item>
      </el-menu>

      <el-table v-if="tabIndex == 1" :data="dataList" v-loading="loading" size="small" height="calc(100% - 130px)"
        @selection-change="selectionChangeHandle" class="table">
        <el-table-column type="selection" width="50" />
        <el-table-column prop="delDate" label="回收时间 " sortable />
        <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip />
        <el-table-column prop="directoryName" label="上传目标" show-overflow-tooltip />
        <el-table-column prop="exhibitionName" label="所属展览" show-overflow-tooltip />
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button icon="el-icon-view" type="text" size="mini" @click="view(scope.row)">查看</el-button>
            <el-button icon="el-icon-top-left" type="text" size="mini" @click="restore(scope.row)">还原
            </el-button>
            <el-button icon="el-icon-delete" type="text" size="mini" @click="del(scope.row)">彻底删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else :data="dataList" v-loading="loading" size="small" height="calc(100% - 130px)"
        @selection-change="selectionChangeHandle" class="table">
        <el-table-column type="selection" width="50" />
        <el-table-column prop="delDate" label="回收时间 " sortable />
        <el-table-column prop="fileName" label="文物名称" show-overflow-tooltip />
        <el-table-column prop="fileName" label="上传目标" show-overflow-tooltip>
          {{ $dictUtils.getDictLabel("my_file", '1', '-') }}
        </el-table-column>
        <el-table-column prop="exhibitionName" label="所属展览" show-overflow-tooltip />
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button icon="el-icon-view" type="text" size="mini" @click="view(scope.row)">查看</el-button>
            <el-button icon="el-icon-top-left" type="text" size="mini" @click="restore(scope.row)">还原
            </el-button>
            <el-button icon="el-icon-delete" type="text" size="mini" @click="del(scope.row)">彻底删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 查看 -->
    <FileDetails ref="fileDetails" @refreshDataList="refreshList"></FileDetails>
    <culturalDetails ref="culturalDetails" @refreshDataList="refreshList"></culturalDetails>
    <!--    日志    -->
    <Log ref="reorganizeLog"></Log>
  </div>
</template>

<script>
import FileDetails from './documents/fileDetails'
import culturalDetails from './cultural/fileDetails'
import Log from "./log"; // 上传日志
export default {
  components: {
    FileDetails,
    Log,
    culturalDetails
  },
  data() {
    return {
      srhStr: '',
      dataList: [
        {
          time: '2021-11-11 13:49:36',
          name: '展览策划案.doc',
          target: 'A展览',
        }
      ],
      loading: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tabIndex: "1",
      dataListSelections: [],
      log: {
        operModular: "2",//模块
        operType: "",//类型
        operTerm: "",//内容
        operSystem: "1",//结果
      }
    }
  },
  mounted() {
    this.refreshList();
  },
  methods: {
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    search() {
      this.pageNo = 1
      this.handleSelectTab();
    },
    handleSelectTab(index) {
      this.pageNo = 1
      if (index) {
        this.tabIndex = index;
      }
      if (this.tabIndex === "1") {
        this.refreshList()
      } else {
        this.refreshList2()
      }
    },
    // 收集整编查询
    refreshList() {
      this.loading = true
      this.$axios(this.api.zlzs.fileCollectList, {
        'name': this.srhStr,
        "delFlag": 1,
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
      }, 'get').then(data => {
        if (data && data.status) {
          this.dataList = data.data.records
          this.total = parseInt(data.data.total)
          this.loading = false
        }
      })
    },
    // 文物清单查询
    refreshList2() {
      this.loading = true
      this.$axios(this.api.zlzs.queryAllByLimitRecycling, {
        'fileName': this.srhStr,
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
      }, 'get').then(data => {
        if (data && data.status) {
          this.dataList = data.data.records
          this.total = parseInt(data.data.total)
          this.loading = false
        }
      })
    },
    // 清空回收站
    emptyRecycle() {
      this.log.operTerm = "清空回收站"
      this.log.operType = '3'
      this.$confirm(`确定清空回收站吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$axios(this.api.zlzs.fileCollectEmptyRecycle, {}, 'delete').then(data => {
          if (data.status) {
            this.$axios(this.api.zlzs.culturalRelicsemptyRecycle, {}, 'delete').then(data => {
              if (data.status) {
                this.$message.success("回收站已清空")
                this.log.operSystem = 0
                this.logSave();
              }
            })
          } else {
            this.log.operSystem = 1
          }

        })
      })
    },
    // 还原
    restore(row) {
      if (row) {
        this.log.operTerm = row.fileName
        this.log.operType = '2'
        this.$confirm(`确定还原所选项吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          let api = this.api.zlzs.fileCollectUpdateById
          let uploadTarget = row.uploadTarget
          let parameter = {
            'id': row.id,
            "delFlag": 0,
            'uploadTarget': uploadTarget
          }
          if (this.tabIndex === "2") {
            api = this.api.zlzs.culturalRelicsDeleteReduction
            uploadTarget = row.exhibitionId
            parameter = {
              'ids': row.id,
              "delFlag": 0,
              'uploadTargets': uploadTarget
            }
          }
          this.$axios(api, parameter, 'put').then(data => {
            if (data.status) {
              this.$message.success("还原成功")
              this.log.operSystem = 0
            } else {
              this.log.operSystem = 1
            }
            this.logSave();
          })
        })
      } else {
        this.log.operType = '2'
        this.$confirm(`确定还原所选项吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          let ids = this.dataListSelections.map(item => {
            return item.id
          }).join(',')

          let api = this.api.zlzs.fileCollectDeleteReduction
          let uploadTarget = this.dataListSelections.map(item => {
            return item.uploadTarget
          }).join(',')

          if (this.tabIndex === "2") {
            api = this.api.zlzs.culturalRelicsDeleteReduction
            uploadTarget = this.dataListSelections.map(item => {
              return item.exhibitionId
            }).join(',')
          }

          this.$axios(api, {
            'ids': ids,
            "delFlag": 0,
            'uploadTargets': uploadTarget
          }, 'put').then(data => {
            if (data.status) {
              this.$message.success("还原成功")
              this.log.operSystem = 0
            } else {
              this.log.operSystem = 1
            }
            this.logSave2(this.dataListSelections, row);
          })
        })
      }

    },
    //新增日志
    logSave() {
      // this.$axios(this.api.zlzs.logSave, this.log, 'post').then(data => {
        this.handleSelectTab()
      // })
    },
    //新增日志
    logSave2(dataListSelections, row) {
      if (row) {
        // this.$axios(this.api.zlzs.logSave, this.log, 'post').then(data => {
          this.handleSelectTab()
        // })
      } else {
        let that = this;
        let batchList = []
        dataListSelections.forEach(function (v) {
          let log = JSON.parse(JSON.stringify(that.log))
          log.operTerm = v.fileName
          batchList.push(log)
        })
        // this.$axios(this.api.zlzs.batchInsertLogin, batchList, 'post').then(data => {
          this.handleSelectTab()
        // })
      }
    },
    //查看日志
    getLog() {
      this.$refs.reorganizeLog.init('2')
    },
    // 查看
    view(row) {
      console.log(row);
      console.log(this.tabIndex);
      if (this.tabIndex === "1") {
        this.$refs.fileDetails.init(row.id, 'recycle')
      } else {
        this.$refs.culturalDetails.init("view", row, null, "recycle")
      }
    },
    // 删除
    del(row) {
      let ids = ''
      if (row) {
        ids = row.id
        this.log.operTerm = row.fileName
      } else {
        ids = this.dataListSelections.map(item => {
          return item.id
        }).join(',')
      }

      this.log.operType = '4'
      this.$confirm(`确定彻底删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        let api = this.api.zlzs.fileCollectRemoveById
        if (this.tabIndex === "2") {
          api = this.api.zlzs.culturalRelicsRemoveById
        }
        this.$axios(api, {
          'ids': ids,
        }, 'delete').then(data => {
          if (data.status) {
            this.$message.success(data.msg)
            this.log.operSystem = 0
          } else {
            this.$message.error(data.msg)
            this.log.operSystem = '1'
          }
          this.logSave2(this.dataListSelections, row);
        })
      })
    },
    // 重置
    resetSearch() {
      this.srhStr = "";
      this.search()
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1
      if (this.tabIndex === "1") {
        this.refreshList()
      } else {
        this.refreshList2()
      }
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      if (this.tabIndex === "1") {
        this.refreshList()
      } else {
        this.refreshList2()
      }
    },
  }
}
</script>

<style scoped></style>
